<template>
  <div class="login">
    <div class="mask" />
    <form
        @submit.prevent="login()">
      <input
          type="password"
          placeholder="Password"
          v-model="state.password" />
      <button class="btn btn-primary">
        Login
      </button>
    </form>
    <svg
        viewBox="0 0 825 103.6"
        class="footer">
      <polygon class="st0" points="-5.5,31 234.4,96.5 836.4,29.8 836.4,116.3 -5.5,117.4" />
      <polygon class="st1" points="826.8,75.7 836.4,75.7 836.4,9.2 234.4,55.9 -5.5,10.4 -5.5,76.8 7.9,76.8 109,224.3 286,287.3 573,217.3 856,313.3" />
      <polygon class="st1" points="-39.3,218.8 -48.9,218.8 -48.9,285.3 553.1,238.6 793,284.1 793,217.7 779.6,217.7 678.5,70.2 501.5,7.2 214.5,77.2 -68.5,-18.8" />
      <polygon class="st1" points="-90,-2.9 609,93.1 828,45.1 914,58.1 865,167.1 530,173.1 366,210.1 23,71.1" />
    </svg>
    <h1 class="order">
      Admin
      <br><small>Bitte melden Sie sich an!</small>
    </h1>
  </div>
</template>


<script>
import { getCurrentInstance, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { buildFormData } from '@/tools.js'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  name: 'Login',

  setup() {
    const instance = getCurrentInstance()
    const store = useStore()
    const router = useRouter()
    const axios = instance.appContext.config.globalProperties.axios
    const state = reactive({
      password: ''
    })

    const login = () => {
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}login`, buildFormData(state))
        .then((response) => {

          console.log(response.data)

          if (response.data.authenticated) {

            // login
            store.commit('logIn')

            // show feedback
            createToast(
              'Login erfolgreich',
              { type: 'success', position: 'bottom-right', showIcon: true }
            )

            // go to admin page
            setTimeout(() => router.push({ name: 'Admin' }), 1000)

          } else errorMsg()
        })
        .catch(() => errorMsg())
    }

    const errorMsg = () => {
      createToast('Login nicht erfolgreich!',
        { type: 'danger', position: 'bottom-right', showIcon: true }
      )
    }

    return {
      state,
      login
    }
  }
}
</script>


<style lang="scss" scoped>
.login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: url('/static/img/bg-werkstatt.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: linear-gradient(180deg, rgba($color: #fff, $alpha: 0.15) 0%, rgba($color: #c7ddf0, $alpha: 0.75));
  }

  form {
    background: rgba($color: #fff, $alpha: 0.25);
    backdrop-filter: blur(3px);
    width: 360px;
    padding: 40px;
    border-radius: 10px;

    input {
      width: 100%;
      border: none;
      border-radius: 5px;
      font-size: 18px;
      padding: 20px;
      margin-bottom: 40px;
      font-weight: 700;
      color: #111;
    }
  }

  .btn {
    width: 100%;
    font-weight: 700;
  }

  svg.footer {
    position: absolute;
    bottom: 0;

    .st0{
      fill: #5b6e7f;
      opacity: 1;
    }

    .st1{
      opacity: 0.25;
      fill: #5b6e7f;
    }
  }

  .order {
    position: fixed;
    z-index: 10;
    left: 1.5rem;
    bottom: 1.25rem;
    color: #fff;
    font-weight: 600;
    font-size: 2.75rem;
    line-height: 0.65em;

    small {
      font-weight: 600;
      font-size: 1.3rem;
      text-transform: uppercase;
      color: #a0cbdf;
    }
  }
}
</style>
